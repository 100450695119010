import { ChatAltIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { userProfile } from "../../../features/user/selectors";

function FeedbackButton() {
	const profile = useSelector(userProfile);
	const [showFeedback, setShowFeedback] = useState(false);

	useEffect(() => {
		if (profile && profile.originatingSystemID) {
			// Hide the feedback button for SWL users
			if (["SWL"].includes(profile.originatingSystemID)) {
				setShowFeedback(false);
			} else {
				setShowFeedback(true);
			}
		}
	}, [profile]);

	return showFeedback ? (
		<div className="fixed -right-8 top-1/2 bg-transparent transform rotate-90 z-20">
			<a href="https://go.crmls.org/crmls-user-feedback/" target="_blank" rel="noreferrer">
				<div className="bg-header text-white rounded-b-md cursor-pointer tracking-wide px-2 py-2 opacity-80 hover:opacity-100 flex justify-center items-center -mt-4">
					<ChatAltIcon className="h-6 w-6 mr-1" /> Feedback
				</div>
			</a>
		</div>
	) : (
		<></>
	);
}

export default FeedbackButton;
